<template>
  <div>
    <SpinnerLoader v-if="obligationExecutionsStatus !== 'success'" :loading="obligationExecutionsStatus" />
    <div
      v-else-if="obligationExecutionsStatus === 'success' && obligationExecutions"
      class="table-responsive mb-0 obligation-executions"
      :class="{ 'with-tabs': tabsCount > 1 }"
    >
      <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
        <thead>
          <tr>
            <th rowspan="3" class="sticky-col" name="agency">
              <div ref="header_agency" class="resizable agency">{{ $t('table.agency') }}</div>
            </th>
            <th rowspan="3" class="sticky-col" style="left: 50px" name="channel_name">
              <div ref="header_channel_name" class="resizable channel_name">{{ $t('channelModal.channel') }}</div>
            </th>
            <th rowspan="3" class="sticky-col" style="left: 100px" name="advertiser">
              <div ref="header_advertiser" class="resizable advertiser">{{ $t('table.advertiser') }}</div>
            </th>
            <th rowspan="3" class="sticky-col" style="left: 150px" name="project">
              <div ref="header_project" class="resizable project">{{ $t('table.project') }}</div>
            </th>
            <th rowspan="3" class="sticky-col" style="left: 200px" name="placement_type">
              <div ref="header_placement_type" class="resizable placement_type">{{ $t('table.type') }}</div>
            </th>
            <th rowspan="3" class="sticky-col" style="left: 250px" name="period">
              <div ref="header_period" class="resizable period">{{ $t('table.period') }}</div>
            </th>
            <th colspan="9" name="inventory">{{ $t('reports.inventory') }}</th>
            <th colspan="12" name="budget">{{ $t('table.budget') }}</th>
          </tr>
          <tr>
            <th colspan="3">{{ $t('channelModal.prime') }}</th>
            <th colspan="3">{{ $t('channelModal.offPrime') }}</th>
            <th colspan="3">{{ $t('booking.total') }}</th>

            <th colspan="4">{{ $t('channelModal.prime') }}</th>
            <th colspan="4">{{ $t('channelModal.offPrime') }}</th>
            <th colspan="4">{{ $t('booking.total') }}</th>
          </tr>
          <tr>
            <!-- INVENTORY PRIME -->
            <th class="text-right">{{ $t('table.commitment') }}</th>
            <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
            <th class="text-right">{{ $t('reports.execution') }}, %</th>
            <!-- INVENTORY OFF-PRIME -->
            <th class="text-right">{{ $t('table.commitment') }}</th>
            <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
            <th class="text-right">{{ $t('reports.execution') }}, %</th>
            <!-- INVENTORY TOTAL -->
            <th class="text-right">{{ $t('table.commitment') }}</th>
            <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
            <th class="text-right">{{ $t('reports.execution') }}, %</th>
            <!-- BUDGET PRIME -->
            <th class="text-right th-budget">{{ $t('table.commitment') }}</th>
            <th class="text-right th-budget">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
            <th class="text-right th-budget">{{ $t('reports.execution') }}, %</th>
            <th class="text-right th-budget">{{ $t('reports.planCppWithDiscount') }}</th>
            <!-- BUDGET OFF-PRIME -->
            <th class="text-right th-budget">{{ $t('table.commitment') }}</th>
            <th class="text-right th-budget">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
            <th class="text-right th-budget">{{ $t('reports.execution') }}, %</th>
            <th class="text-right th-budget">{{ $t('reports.planCppWithDiscount') }}</th>
            <!-- BUDGET TOTAL -->
            <th class="text-right th-budget">{{ $t('table.commitment') }}</th>
            <th class="text-right th-budget">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
            <th class="text-right th-budget">{{ $t('reports.execution') }}, %</th>
            <th class="text-right th-budget">{{ $t('reports.planCppWithDiscount') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in obligationExecutions[selectedTab].items"
            :key="`${item.project_id}_${item.placement_type_id}_${item.advertiser_id}_${item.channel_id}_${item.month}`"
          >
            <td name="agency" class="sticky-col">{{ item.agency_name }}</td>
            <td name="channel_name" class="sticky-col" style="left: 50px">{{ item.channel_name }}</td>
            <td name="advertiser" class="sticky-col" style="left: 100px">{{ item.advertiser_name }}</td>
            <td name="project" class="sticky-col" style="left: 150px">{{ item.project_name }}</td>
            <td name="placement_type" class="sticky-col" style="left: 200px">{{ item.placement_type_name }}</td>
            <td name="period" class="sticky-col" style="left: 250px">{{ item.month ? months[+item.month - 1] : '' }} {{ item.year }}</td>
            <td class="text-right commitment">{{ item.inventory_prime_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ item.inventory_prime_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+item.inventory_prime_execution_percent).toFixed(2) }}%</td>

            <td class="text-right commitment">{{ item.inventory_off_prime_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ item.inventory_off_prime_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+item.inventory_off_prime_execution_percent).toFixed(2) }}%</td>

            <td class="text-right commitment">{{ item.inventory_total_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ item.inventory_total_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+item.inventory_total_execution_percent).toFixed(2) }}%</td>

            <td class="text-right commitment">{{ item.budget_prime_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ item.budget_prime_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+item.budget_prime_execution_percent).toFixed(2) }}%</td>
            <td class="text-right">{{ item.budget_prime_plan_cpp_with_discount | toFixedAndSpace }}</td>

            <td class="text-right commitment">{{ item.budget_off_prime_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ item.budget_off_prime_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+item.budget_off_prime_execution_percent).toFixed(2) }}%</td>
            <td class="text-right">{{ item.budget_off_prime_plan_cpp_with_discount | toFixedAndSpace }}</td>

            <td class="text-right commitment">{{ item.budget_total_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ item.budget_total_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+item.budget_total_execution_percent).toFixed(2) }}%</td>
            <td class="text-right">{{ item.budget_total_plan_cpp_with_discount | toFixedAndSpace }}</td>
          </tr>
        </tbody>
        <tfoot v-if="obligationExecutions[selectedTab].totals" class="font-weight-bold">
          <tr>
            <td name="total" colspan="6" class="sticky-col" style="z-index: 11">{{ $t('booking.total') }}</td>
            <td class="text-right commitment">{{ obligationExecutions[selectedTab].totals.inventory_prime_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ obligationExecutions[selectedTab].totals.inventory_prime_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+obligationExecutions[selectedTab].totals.inventory_prime_execution_percent).toFixed(2) }}%</td>

            <td class="text-right commitment">{{ obligationExecutions[selectedTab].totals.inventory_off_prime_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ obligationExecutions[selectedTab].totals.inventory_off_prime_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+obligationExecutions[selectedTab].totals.inventory_off_prime_execution_percent).toFixed(2) }}%</td>

            <td class="text-right commitment">{{ obligationExecutions[selectedTab].totals.inventory_total_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ obligationExecutions[selectedTab].totals.inventory_total_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+obligationExecutions[selectedTab].totals.inventory_total_execution_percent).toFixed(2) }}%</td>
            <td class="text-right commitment">{{ obligationExecutions[selectedTab].totals.budget_prime_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ obligationExecutions[selectedTab].totals.budget_prime_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+obligationExecutions[selectedTab].totals.budget_prime_execution_percent).toFixed(2) }}%</td>
            <td class="text-right">{{ obligationExecutions[selectedTab].totals.budget_prime_plan_cpp_with_discount | toFixedAndSpace }}</td>

            <td class="text-right commitment">{{ obligationExecutions[selectedTab].totals.budget_off_prime_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ obligationExecutions[selectedTab].totals.budget_off_prime_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+obligationExecutions[selectedTab].totals.budget_off_prime_execution_percent).toFixed(2) }}%</td>
            <td class="text-right">{{ obligationExecutions[selectedTab].totals.budget_off_prime_plan_cpp_with_discount | toFixedAndSpace }}</td>

            <td class="text-right commitment">{{ obligationExecutions[selectedTab].totals.budget_total_commitment | toFixedAndSpace }}</td>
            <td class="text-right">{{ obligationExecutions[selectedTab].totals.budget_total_plan_fact | toFixedAndSpace }}</td>
            <td class="text-right">{{ (+obligationExecutions[selectedTab].totals.budget_total_execution_percent).toFixed(2) }}%</td>
            <td class="text-right">{{ obligationExecutions[selectedTab].totals.budget_total_plan_cpp_with_discount | toFixedAndSpace }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div
      v-if="tabsCount > 1"
      class="d-flex px-3 py-2 overflow-auto position-fixed"
      style="left: 0; bottom: 0; right: 0; white-space: nowrap; width: 100vw; z-index: 100"
    >
      <b-button
        v-for="(item, name) in obligationExecutions"
        :key="name"
        size="sm"
        :variant="selectedTab === name ? 'primary' : 'link'"
        @click="changeActiveTab(name)"
      >
        {{ name }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
//import VirtualScroll from '@/components/VirtualScroll';

export default {
  name: 'ObligationExecutionsTable',
  components: { SpinnerLoader /*VirtualScroll*/ },
  filters: { toFixedAndSpace },
  data() {
    return {
      resizeChannelColObserver: new ResizeObserver(this.resizeCol),
      selectedTab: 'All',
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      obligationExecutions: 'getReportObligationExecutions',
      obligationExecutionsStatus: 'getReportObligationExecutionsStatus',
    }),
    months() {
      return [
        this.$i18n.t('month.jan'),
        this.$i18n.t('month.feb'),
        this.$i18n.t('month.mar'),
        this.$i18n.t('month.apr'),
        this.$i18n.t('month.may'),
        this.$i18n.t('month.jun'),
        this.$i18n.t('month.jul'),
        this.$i18n.t('month.aug'),
        this.$i18n.t('month.sep'),
        this.$i18n.t('month.oct'),
        this.$i18n.t('month.nov'),
        this.$i18n.t('month.dec'),
      ];
    },
    tabsCount() {
      if (this.obligationExecutionsStatus === 'success' && this.obligationExecutions) {
        return Object.keys(this.obligationExecutions).length;
      } else return 1;
    },
  },
  watch: {
    obligationExecutionsStatus(newValue) {
      if (this.$refs['header_agency']) this.resizeChannelColObserver.unobserve(this.$refs['header_agency']);
      if (this.$refs['header_channel_name']) this.resizeChannelColObserver.unobserve(this.$refs['header_channel_name']);
      if (this.$refs['header_advertiser']) this.resizeChannelColObserver.unobserve(this.$refs['header_advertiser']);
      if (this.$refs['header_project']) this.resizeChannelColObserver.unobserve(this.$refs['header_project']);
      if (this.$refs['header_placement_type']) this.resizeChannelColObserver.unobserve(this.$refs['header_placement_type']);
      if (this.$refs['header_period']) this.resizeChannelColObserver.unobserve(this.$refs['header_period']);
      if (!(this.obligationExecutionsStatus === 'success' && this.obligationExecutions?.items)) return;
      // Setup resize handlers
      this.$nextTick(() => {
        if (this.$refs['header_agency']) this.resizeChannelColObserver.observe(this.$refs['header_agency']);
        if (this.$refs['header_channel_name']) this.resizeChannelColObserver.observe(this.$refs['header_channel_name']);
        if (this.$refs['header_advertiser']) this.resizeChannelColObserver.observe(this.$refs['header_advertiser']);
        if (this.$refs['header_project']) this.resizeChannelColObserver.observe(this.$refs['header_project']);
        if (this.$refs['header_placement_type']) this.resizeChannelColObserver.observe(this.$refs['header_placement_type']);
        if (this.$refs['header_period']) this.resizeChannelColObserver.observe(this.$refs['header_period']);
      });
    },
    obligationExecutions() {
      if (this.obligationExecutionsStatus === 'success' && this.obligationExecutions) {
        this.selectedTab = Object.keys(this.obligationExecutions)[0];
      }
    },
  },
  destroyed() {
    this.$store.commit('clearReportObligationExecutions');
  },
  methods: {
    resizeCol(entries) {
      //set table left offset because of wrapper padding
      const wrapperLeftPadding = document.querySelector('div.table-responsive.obligation-executions')?.getBoundingClientRect()?.left;
      for (let entry of entries) {
        const colClassName = entry.target.className.split(' ')[1]; // important to keep col class name on the seconds place in template!
        if (colClassName === 'header_period') return;
        // get parent TH and its left position
        const parentTh = entry.target.parentElement;
        if (!parentTh) return;
        // set next TH
        let nextSiblingEl = parentTh.nextSibling;
        let currentThOffsetRight = parentTh.getBoundingClientRect().left + parentTh.offsetWidth - wrapperLeftPadding;

        // set new left pos for all td-s in this & next sticky columns
        while (nextSiblingEl && nextSiblingEl.getAttribute('name') !== 'inventory') {
          const leftDiff = currentThOffsetRight - nextSiblingEl.style.left.slice(0, -2);
          let newLeftPos = +nextSiblingEl.style.left.slice(0, -2) + leftDiff;
          nextSiblingEl.style.left = newLeftPos + 'px';
          const colTdsArray = document.querySelectorAll(`td[name=${nextSiblingEl.getAttribute('name')}]`);
          colTdsArray.forEach((td) => (td.style.left = newLeftPos + 'px'));

          // go next
          currentThOffsetRight = nextSiblingEl.getBoundingClientRect().left + nextSiblingEl.offsetWidth - wrapperLeftPadding;
          nextSiblingEl = nextSiblingEl.nextSibling;
        }
      }
    },
    changeActiveTab(name) {
      this.selectedTab = name;
    },
  },
};
</script>
<style lang="sass">

.reports-page div.table-responsive.obligation-executions
  height: calc(100vh - 210px)
  &.with-tabs
    height: calc(100vh - 250px)
  .white-space-initial
    white-space: initial

.reports-page .obligation-executions table.table
  div.resizable
    &.channel_name,
    &.placement_type,
    &.agency,
    &.advertiser,
    &.project,
    &.period
      min-width: 50px

  td[name="channel_name"],
  td[name="placement_type"],
  td[name="agency"],
  td[name="project"],
  td[name="advertiser"],
  td[name="period"]
    max-width: 50px

  td.commitment
    min-width: 80px

  th.th-budget
    min-width: 100px

@media (min-width: 2286px)
  .reports-page div.table-responsive.obligation-executions
    height: calc(100vh - 154px)
</style>
